import { lazy, Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/custom.css'
import './assets/scss/style.scss'
import Spinner from './components/spinner/Spinner'
import { datadogRum } from '@datadog/browser-rum'

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'adviceplace',
        env: 'prod',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'mask-user-input',
        enablePrivacyForActionName: true,
    })
}

const App = lazy(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import('./app')), 0)
        }),
)

const MicrosoftClarityScript = () => {
    useEffect(() => {
        function loadClarityScript(document, tagName, scriptId) {
            const script = document.createElement(tagName)
            script.async = true
            script.src = `https://www.clarity.ms/tag/${scriptId}`
            const firstScript = document.getElementsByTagName(tagName)[0]
            firstScript.parentNode.insertBefore(script, firstScript)
        }

        function initializeClarity(window, document, clarityName, tagName, scriptId) {
            window[clarityName] =
                window[clarityName] ||
                function () {
                    ;(window[clarityName].q = window[clarityName].q || []).push(arguments)
                }
            loadClarityScript(document, tagName, scriptId)
        }
        // Código do Microsoft Clarity
        initializeClarity(window, document, 'clarity', 'script', 'ofixda8let')
        // }
    }, [])

    return null
}

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <MicrosoftClarityScript />
        <App />
    </Suspense>,
    document.getElementById('root'),
)
